






































































































































































import { Component, Vue } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import { UserResponseType } from '@/types/user'
import { Upload } from 'element-ui'
import SelectCommon from '@/components/common/SelectCommon.vue'
import { AllVendorsType, VendorOptionsType } from '@/types/vendors'

export type FormDataType = {
  vendor: null | string
  importFile: any
  QR: boolean
  reportName: ''
  reportNote: ''
}

@Component({
  components: { SelectCommon, TextAreaCommon, InputCommon, DialogInfo },
})
export default class CreateCodes extends Vue {
  form: FormDataType = {
    vendor: null,
    importFile: null,
    QR: true,
    reportName: '',
    reportNote: '',
  }

  fileName = ''

  createCode = false
  createCodeError = false
  isLoading = false

  vendorOptions: VendorOptionsType[] = []

  $refs!: {
    upload: Upload
  }

  get email(): string {
    return this.$store.getters['codes/successEmail']
  }

  get userData(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get importResponse(): string {
    return this.$store.getters['codes/importResponse']
  }

  get vendors(): AllVendorsType[] {
    return this.$store.getters['vendors/allVendors']
  }

  handleUploadFile(file): void {
    this.form.importFile = file.raw
    this.fileName = file.name
  }

  handleDeleteFile(): void {
    this.form.importFile = null
    this.$refs.upload.clearFiles()
  }

  openDialogCodeError(): void {
    this.createCodeError = !this.createCodeError
  }

  openDialogCode(): void {
    this.createCode = !this.createCode
  }

  async handleCreateCodes(): Promise<void> {
    if (this.userData.role !== 'admin') {
      this.form.vendor = this.userData.vendor_id
    }

    if (this.form.importFile && this.form.vendor) {
      this.isLoading = true

      await this.$store.dispatch('codes/importCodes', this.form)

      this.isLoading = false
      if (this.importResponse) {
        this.openDialogCode()
      } else {
        this.openDialogCodeError()
      }
    } else {
      this.openDialogCodeError()
    }
  }

  handleDownloadCode(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + '/api/file/downloadExample/'
    )
    link.setAttribute('download', 'exampleFile')

    link.click()

    link.remove()
  }

  handleRouteBack(): void {
    this.$router.push({ name: 'Codes' })
  }

  handleRedirect(): void {
    this.$router.push({
      name: 'Codes',
      params: { activeTab: '1' },
    })
  }

  async setVendors(): Promise<void> {
    this.vendors.forEach((vendor: AllVendorsType) => {
      this.vendorOptions.push({
        label: vendor.name,
        value: vendor.id,
      })
    })
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('user/getUserData')
    await this.$store.dispatch('vendors/getAllVendors')
    await this.setVendors()
  }
}
